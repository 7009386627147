import { addDoc, collection } from 'firebase/firestore';
import { auth, db } from '../firebase/firebaseConfig'; // Importa las instancias desde la configuración

export const saveEvaluationToHistory = async (correctas, incorrectas, totalPreguntas, tipoEvaluacion) => {
  try {
    const user = auth.currentUser; // Obtén el usuario actual autenticado desde Firebase Auth

    // Construye la evaluación
    const evaluation = {
      correctas,
      incorrectas,
      totalPreguntas,
      tipoEvaluacion,
      fecha: new Date(),
      userId: user ? user.uid : 'anónimo', // Incluye el UID del usuario autenticado o 'anónimo'
    };

    // Si el usuario está autenticado, guarda en Firestore
    if (user) {
      await addDoc(collection(db, 'history'), evaluation);
    }

    // Guarda localmente si no se puede guardar en Firestore
    const localData = JSON.parse(localStorage.getItem('historyEvaluations')) || [];
    localData.push(evaluation);
    localStorage.setItem('historyEvaluations', JSON.stringify(localData));

  } catch (error) {
    console.error('Error al guardar la evaluación:', error);
  }
};

 