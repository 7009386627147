import React from 'react';
import { Accordion, Container } from 'react-bootstrap';

const PrivacyPolicy = () => {
  return (
    <Container className="mt-5">
      <h1 className="text-center">Política de Privacidad</h1>
      <p className="text-muted text-center">Última actualización: 30 de noviembre de 2024</p>
      <p>
        Al instalar nuestra aplicación móvil o el uso de nuestra plataforma usted acepta cumplir y estar sujeto a la siguiente política de privacidad. Lea cuidadosamente los términos para entender cómo manejamos su información.
      </p>

      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Información que recopilamos</Accordion.Header>
          <Accordion.Body>
            Cuando instala una aplicación afiliada en su dispositivo, podemos recopilar automáticamente cierta información como:
            <ul>
              <li>Datos técnicos: marca y modelo del dispositivo, sistema operativo, dirección IP y más.</li>
              <li>Información de ubicación: geolocalización precisa según los permisos otorgados.</li>
              <li>Aplicaciones instaladas: una lista de otras aplicaciones presentes en su dispositivo.</li>
            </ul>
            <p>
              Toda la información se recopila bajo estrictas medidas de privacidad y se utiliza para brindar servicios relevantes y significativos.
            </p>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>Cómo usamos la información</Accordion.Header>
          <Accordion.Body>
            Utilizamos la información recopilada para:
            <ul>
              <li>Personalizar anuncios y recomendaciones relevantes.</li>
              <li>Optimizar la experiencia del usuario en nuestra plataforma.</li>
              <li>Realizar análisis estadísticos para mejorar continuamente nuestro servicio.</li>
            </ul>
            <p>
              Nos aseguramos de que cualquier dato compartido con terceros sea anonimizado y utilizado solo para fines legítimos, como análisis publicitarios o investigación.
            </p>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>Optar por no participar</Accordion.Header>
          <Accordion.Body>
            <p>
              Usted tiene el derecho de optar por no participar en la recopilación de datos. Esto puede lograrse desinstalando nuestras aplicaciones o modificando las configuraciones de privacidad en su dispositivo.
            </p>
            <p>
              Sin embargo, tenga en cuenta que ciertas funcionalidades pueden perderse como resultado. Si desea que eliminemos datos previamente recopilados, puede contactarnos a través de nuestro sitio web.
            </p>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>Uso apropiado</Accordion.Header>
          <Accordion.Body>
            <p>
              Al utilizar nuestros servicios, usted se compromete a:
            </p>
            <ul>
              <li>No participar en actividades ilegales, fraudulentas o malintencionadas.</li>
              <li>No interferir con la experiencia de otros usuarios.</li>
              <li>Respetar nuestras políticas y términos de servicio.</li>
            </ul>
            <p>
              Nos reservamos el derecho de investigar cualquier uso indebido y tomar medidas adecuadas para garantizar la seguridad de la plataforma.
            </p>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header>Materiales no solicitados</Accordion.Header>
          <Accordion.Body>
            <p>
              Cualquier material no solicitado enviado o enviado a nuestra plataforma no se considerará confidencial o secreto. Nos reservamos el derecho de utilizar dicho material para mejorar nuestros servicios sin compensación adicional.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
};

export default PrivacyPolicy;