import React from 'react';

const AboutUs = () => {
  return (
    <div className="container mt-5">
      <header className="text-center mb-5">
        <h1 className="display-4">Medstudio: Innovación y Excelencia</h1>
        <p className="lead">
          En plataformas web y aplicaciones móviles, redefiniendo el futuro.
        </p>
      </header>
      <div className="row">
        <div className="text-center col-md-6">
          <h2>Innovación al Servicio del Usuario</h2>
          <p>
            En un mundo en constante evolución tecnológica, donde la digitalización se ha convertido en el motor de cambio para casi todos los aspectos de la vida, Medstudio surge como una empresa visionaria y comprometida con la excelencia en el desarrollo de plataformas web y aplicaciones móviles. Nuestra misión no se limita únicamente a crear herramientas funcionales, sino que también abarca la responsabilidad de ofrecer experiencias significativas, transformadoras y accesibles para nuestros usuarios.
          </p>
        </div>
        <div className="text-center col-md-6">
          <h2>Compromiso con la Educación</h2>
          <p>
            Nuestra visión como empresa va más allá de lo puramente comercial. Creemos firmemente en el poder transformador de la educación y el aprendizaje continuo. Por ello, muchas de nuestras plataformas web y aplicaciones móviles están diseñadas específicamente para facilitar el acceso al conocimiento, mejorar los procesos de estudio y promover el desarrollo personal y profesional de nuestros usuarios.
          </p>
        </div>
      </div>
      <hr className="my-5" />
      <div className="row">
        <div className="text-center col-md-6">
          <h2>Tecnología que Transforma</h2>
          <p>
            La base de nuestro éxito radica en la tecnología de punta que empleamos en cada proyecto. Nos aseguramos de trabajar con los frameworks y lenguajes de programación más modernos, como ReactJS, Node.js y Firebase, para desarrollar aplicaciones robustas, escalables y seguras.
          </p>
        </div>
        <div className="text-center col-md-6">
          <h2>Un Equipo Técnico de Excelencia</h2>
          <p>
            Detrás de cada plataforma web y aplicación móvil desarrollada por Medstudio, hay un equipo técnico altamente capacitado, apasionado y dedicado. Nuestro equipo está compuesto por diseñadores, desarrolladores, analistas y especialistas en marketing digital que trabajan en estrecha colaboración para garantizar que cada proyecto cumpla con los más altos estándares de calidad.
          </p>
        </div>
      </div>
      <hr className="my-5" />
      <div className="row">
        <div className="text-center col-md-6">
          <h2>La Mejor Experiencia de Usuario</h2>
          <p>
            Desde el momento en que un usuario interactúa por primera vez con una de nuestras plataformas, nos aseguramos de que cada elemento esté diseñado para proporcionar una experiencia fluida, agradable y memorable. Escuchamos activamente a nuestros usuarios para mejorar continuamente nuestros productos.
          </p>
        </div>
        <div className=" text-center col-md-6">
          <h2>Impacto en la Comunidad</h2>
          <p>
            Como empresa, Medstudio también se compromete a tener un impacto positivo en la comunidad. Dedicamos una parte de nuestros recursos a proyectos que benefician a sectores desfavorecidos y fomentamos la inclusión y la diversidad tanto dentro de nuestra empresa como en los productos que desarrollamos.
          </p>
        </div>
      </div>
      <footer className="text-center mt-5">
        <h3>Medstudio: Una Empresa del Futuro</h3>
        <p>
          En Medstudio, no solo desarrollamos plataformas web y aplicaciones móviles; construimos experiencias, conexiones y oportunidades. Estamos moldeando un futuro donde la tecnología sea un facilitador del éxito y el aprendizaje.
        </p>
      </footer>
    </div>
  );
};

export default AboutUs;