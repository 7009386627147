import React from 'react';
import { Card, Form } from 'react-bootstrap';

const QuestionCard = ({ question, questionIndex, userAnswer, onAnswerChange }) => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>{question.question}</Card.Title>
        {question.image && <Card.Img src={question.image} alt="Imagen de la pregunta" />}
        <Form>
          {question.options.map((option, idx) => (
            <Form.Check
              type="radio"
              name={`question-${questionIndex}`}
              id={`question-${questionIndex}-option-${idx}`}
              label={option}
              value={option}
              checked={userAnswer === option}
              onChange={() => onAnswerChange(questionIndex, option)}
              key={idx}
            />
          ))}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default QuestionCard;