import React, { useEffect, useState } from "react";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import { saveEvaluationToHistory } from "./historyUtils";

const FeedbackModal = ({ show, onClose, questions, userAnswers }) => {
  const [showDetailedFeedback, setShowDetailedFeedback] = useState(false);

  const totalRespondidas = Object.keys(userAnswers).length;
  const totalCorrectas = Object.keys(userAnswers).filter(
    (index) => userAnswers[index] === questions[index]?.answer
  ).length;
  const totalIncorrectas = totalRespondidas - totalCorrectas;

  const porcentajeCorrectas =
    totalRespondidas > 0 ? (totalCorrectas / totalRespondidas) * 100 : 0;

  const aprobado = porcentajeCorrectas >= 50.99;

  // Guardar feedback en Firebase al finalizar el examen
  useEffect(() => {
    if (show) {
      saveEvaluationToHistory(
        totalCorrectas,
        totalIncorrectas,
        totalRespondidas,
        porcentajeCorrectas.toFixed(2),
        aprobado
      );
    }
  }, [show, totalCorrectas, totalIncorrectas, totalRespondidas, porcentajeCorrectas, aprobado]);

  // Mostrar feedback detallado
  const handleShowDetailedFeedback = () => {
    setShowDetailedFeedback(true);
  };

  // Filtrar preguntas respondidas
  const answeredQuestions = questions.filter((_, index) => userAnswers[index]);

  return (
    <Modal show={show} onHide={onClose} size="lg" centered>
      <Modal.Body>
        {showDetailedFeedback ? (
          <Container>
            <h2 className="text-center">Feedback Detallado</h2>
            {answeredQuestions.length > 0 ? (
              answeredQuestions.map((question, index) => {
                const userAnswer = userAnswers[index];
                const isCorrect = userAnswer === question.answer;

                return (
                  <Row key={index} className="mb-3">
                    <Col>
                      <h5>{`Pregunta ${index + 1}: ${question.question}`}</h5>
                      <p>
                        <strong>Tu respuesta:</strong> {userAnswer}
                      </p>
                      <p>
                        <strong>Respuesta correcta:</strong> {question.answer}
                      </p>
                      <p>
                        {isCorrect ? (
                          <span style={{ color: "green" }}>¡Correcto!</span>
                        ) : (
                          <span style={{ color: "red" }}>Incorrecto.</span>
                        )}
                      </p>
                      {question.explanation && (
                        <p>
                          <strong>Explicación:</strong> {question.explanation}
                        </p>
                      )}
                      <hr />
                    </Col>
                  </Row>
                );
              })
            ) : (
              <h5 className="text-center">No se respondieron preguntas.</h5>
            )}
          </Container>
        ) : (
          <div>
            <h2>Resultados del examen</h2>
            <p>Total de preguntas respondidas: {totalRespondidas}</p>
            <p>Correctas: {totalCorrectas}</p>
            <p>Incorrectas: {totalIncorrectas}</p>
            <p>Porcentaje de aciertos: {porcentajeCorrectas.toFixed(2)}%</p>

            {aprobado ? (
              <h3 style={{ color: "green", fontSize: "1.5rem" }}>
                ¡Felicidades! Aprobaste.
              </h3>
            ) : (
              <h3 style={{ color: "red", fontSize: "1.5rem" }}>
                No te desanimes, ¡sigue estudiando! La práctica te llevará al éxito.
              </h3>
            )}

            <Button
              variant="primary"
              onClick={handleShowDetailedFeedback}
              className="mt-3"
            >
              Ver Feedback Detallado
            </Button>
          </div>
        )}
        <Button variant="secondary" onClick={onClose} className="mt-3">
          Cerrar
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default FeedbackModal;