import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import QuestionCard from './exam/QuestionCard';
import FeedbackModal from './exam/FeedbackModal';
import { saveEvaluationToHistory } from './exam/historyUtils';
import './components/css/ztyle.css';

const Exam = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [finished, setFinished] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [examStarted, setExamStarted] = useState(false);
  const [selectedTime, setSelectedTime] = useState(30 * 60);
  const [showModal, setShowModal] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  useEffect(() => {
    fetch('./questions.json')
      .then((response) => response.json())
      .then((data) => setQuestions(data))
      .catch((error) => console.error('Error al cargar las preguntas:', error));
  }, []);

  useEffect(() => {
    if (examStarted && timeRemaining > 0) {
      const timer = setInterval(() => {
        setTimeRemaining((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            setFinished(true);
            setShowModal(true);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [examStarted, timeRemaining]);

  const handleAnswerChange = (index, option) => {
    setUserAnswers({ ...userAnswers, [index]: option });
  };

  const handleNext = () => {
    if (currentQuestionIndex + 5 < questions.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 5);
    } else {
      setFinished(true);
      setShowModal(true);
    }
  };

  const handleStartExam = () => {
    setTimeRemaining(selectedTime);
    setExamStarted(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowFeedback(true);
  };

  useEffect(() => {
    if (finished) {
      const totalCorrect = Object.keys(userAnswers).filter(
        (index) => userAnswers[index] === questions[index].answer
      ).length;
      const totalIncorrect = Object.keys(userAnswers).length - totalCorrect;

      saveEvaluationToHistory(totalCorrect, totalIncorrect, questions.length, 'examen');
    }
  }, [finished, questions.length, userAnswers]);

  return (
    <Container>
      {!examStarted ? (
        <div className="text-center mt-5">
          <h2>Examen</h2>
          <Form>
            <Form.Group controlId="formTimeSelect">
              <Form.Label>Selecciona el tiempo del examen:</Form.Label>
              <Form.Control
                as="select"
                value={selectedTime}
                onChange={(e) => setSelectedTime(Number(e.target.value))}
              >
                <option value={10 * 60}>10 minutos</option>
                <option value={20 * 60}>20 minutos</option>
                <option value={30 * 60}>30 minutos</option>
                <option value={60 * 60}>60 minutos</option>
                <option value={90 * 60}>90 minutos</option>
                <option value={180 * 60}>180 minutos</option>
              </Form.Control>
            </Form.Group>
            <Button variant="primary" onClick={handleStartExam} className="mt-3">
              Comenzar Examen
            </Button>
          </Form>
        </div>
      ) : (
        <>
          <Row>
            <Col>
              <h3>Tiempo restante: {Math.floor(timeRemaining / 60)}:{timeRemaining % 60}</h3>
            </Col>
          </Row>
          <Row>
            {questions.slice(currentQuestionIndex, currentQuestionIndex + 5).map((question, index) => (
              <QuestionCard
                key={index}
                question={question}
                questionIndex={currentQuestionIndex + index}
                userAnswer={userAnswers[currentQuestionIndex + index]}
                onAnswerChange={handleAnswerChange}
              />
            ))}
          </Row>
          <Button variant="primary" onClick={handleNext} className="mt-3">
            Siguiente
          </Button>
        </>
      )}

      <FeedbackModal
        show={showModal}
        onClose={handleCloseModal}
        questions={questions}
        userAnswers={userAnswers}
      />
    </Container>
  );
};

export default Exam;